export default {
    colors: {
        // default: "#344675",
        primary: "#f14c04",
        default: "#344675",
        // primary: "#3358f4",
        info: "#1d8cf8",
        danger: "#fd5d93",
        teal: "#00d6b4",
        primaryGradient: ['rgba(76, 211, 150, 0.1)', 'rgba(53, 183, 125, 0)', 'rgba(119,52,169,0)'],
    }
}
