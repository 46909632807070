<template>
  <c-box p="2">
    <c-flex justify="space-between" mb="3">
      <c-heading size="lg" as="h3" color="rgba(0,48,143)">
        Invite Businesses
      </c-heading>
    </c-flex>

    <!-- Form Control for Email Input and Submit Button -->
    <c-form-control mt="7">
      <c-flex justify="start" align="center">
        <c-input
            flex="1"
            type="email"
            fontSize="sm"
            placeholder="Enter Email Addresses"
            v-model="email"
            mr="2"
        />
        <c-button
            right-icon="paper-plane"
            size="sm"
            variant-color="vc-orange"
            @click="handleSubmit"
        >
          Send
        </c-button>
      </c-flex>
    </c-form-control>

    <!-- Help Text -->
    <c-text mt="2" fontWeight="500" color="primary" fontSize="xs">
      Separate multiple entries with a comma, space, or line break.
    </c-text>

    <!-- Static Message Box -->
    <c-box rounded="md" mt="5" border="1px" borderColor="gray.300" p="7" color="grey">
      <c-text fontSize="sm" fontWeight="700" color="grey" mb="5">
        We'll include this message in your email:
      </c-text>
      <c-text fontWeight="400" fontSize="xs">
        Hi there, I would like to introduce you to VibrantFounder.<br>
        VibrantFounder can help you kick-start your business from the idea stage through to going to the market leveraging on AI tools to make life easy.
      </c-text>
    </c-box>

    <!-- Optional Custom Message Textarea -->
    <c-textarea
        fontSize="sm"
        placeholder="Your Message (optional)"
        v-model="message"
        mt="6"
    />

    <!-- Feedback Message on Submission -->
    <c-alert
        mt="4"
        v-if="feedbackMessage"
        :status="feedbackStatus"
    >
      {{ feedbackMessage }}
    </c-alert>
  </c-box>
</template>

<script>
import axiosInstance from "@/helpers/api";

export default {
  name: "InviteBusinessesCard",
  data() {
    return {
      email: '',
      message: '',
      feedbackMessage: '',
      feedbackStatus: ''
    };
  },
  methods: {
    async handleSubmit() {
      console.log('Startlingxxx=>')
      // Prepare the data payload
      const emails = this.email.split(/[\s,]+/).filter(Boolean); // Split by commas, spaces, or line breaks and remove empty entries
      const payload = {
        emailAddress: emails,
        content: this.message || 'Hi there, I would like to introduce you to VibrantFounder. VibrantFounder can help you kick-start your business from the idea stage through to going to the market leveraging on AI tools to make life easy.'
      };

      const apiUrl = '/helpers/affiliate/invitation/send';

      try {
        await axiosInstance.post(apiUrl, payload)
            .then((response) => {
              if (response.status === 201) {
                this.feedbackMessage = 'Invitation sent successfully!';
                this.feedbackStatus = 'success';
                this.email = '';
                this.message = '';
              }
            })
      } catch (error) {
        // Handle errors
        this.feedbackMessage = 'Failed to send invitation. Please try again.';
        this.feedbackStatus = 'error';
        console.error(error);
      } finally {
        // Clear the feedback message after a delay
        setTimeout(() => {
          this.feedbackMessage = '';
        }, 5000);
      }
    }
  }
};
</script>

<style scoped>
/* Optional: Add custom styling if needed */
</style>
