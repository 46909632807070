<template>
  <c-box p="2">
    <c-flex justify="space-between" mb="3">
      <c-heading size="lg" as="h3" color="rgba(0,48,143)">
        Referrals
      </c-heading>
      <c-box w="120px" size="sm">
        <c-select
            backgroundColor="tomato"
            borderColor="tomato"
            rounded="md"
            h="30px"
            fontSize="14px"
            color="white"
            v-model="currentPackage"
            :placeholder="currentPackage"
        >
          <option value="freemium">Freemium</option>
          <option value="premium">Premium</option>
          <option value="pro">Pro</option>
        </c-select>
      </c-box>
    </c-flex>

    <!-- Use PrimeVue's DataTable for displaying the filtered referrals -->
    <DataTable
        class="table-body p-datatable-sm"
        v-if="filteredReferrals && filteredReferrals.length"
        :value="filteredReferrals"
        :rows="10"
        :totalRecords="total"
        style="width: 100%;"
    >
      <Column field="name" header="Business" sortable />
      <Column field="plan" header="Plan" />
      <Column field="contactEmail" header="Email" />
      <Column field="country" header="Country" />
    </DataTable>

    <!-- Show an empty state if no referrals are found -->
    <c-flex
        flexDirection="column"
        v-else
        justify="center"
        align="center"
        w="100%"
        h="100%"
    >
      <img
          style="z-index: 55"
          height="300px"
          width="300px"
          src="@/assets/img/empty.svg#empty-state"
          alt="empty state"
      />
      <c-text fontSize="15px" fontWeight="700" mt="50px">
        No coupon has been used yet
      </c-text>
    </c-flex>
  </c-box>
</template>



<script>
import DataTable from 'primevue/datatable';
import { mapGetters } from 'vuex';
import { getAffiliateReferralCompanies } from '@/services/company';

export default {
  name: 'ReferralBusinessesCard',
  components: {
    DataTable
  },
  computed: {
    ...mapGetters({
      currentCompany: 'company/getActiveCompany',
    }),
    formattedReferrals() {
      if (this.referrals && this.referrals.length) {
        return this.referrals.map((item) => ({
          id: item.referred_company.id,
          name: item.referred_company.name,
          contactEmail: item.referred_company.contactEmail,
          country: item.referred_company.country,
          plan: item.Subscription ? `${item.Subscription.plan.name} ${item.Subscription.plan.plan_prices.frequency}` : 'Freemium',
        }));
      }
      return [];
    },
    filteredReferrals() {
      return this.formattedReferrals.filter((referral) => {
        return this.currentPackage === 'freemium'
            ? referral.plan === 'Freemium'
            : referral.plan === this.currentPackage;
      });
    },
  },
  data() {
    return {
      total: 0,
      query: {},
      referrals: null,
      currentPackage: 'freemium',
    };
  },
  methods: {
    async getCompanies() {
      try {
        const response = await this.getAffiliatesReferralCompanies();
        if (response.success) {
          this.referrals = response.data;
          console.log('Data here', this.referrals);
        } else {
          this.referrals = [];
          console.log('Something went wrong');
        }
      } catch (e) {
        console.log('Something went wrong. Please try again.');
      }
    },
    async getAffiliatesReferralCompanies() {
      try {
        const response = await getAffiliateReferralCompanies(this.currentCompany.id, 10, 0);
        console.log('logx', response);
        return { success: true, data: response.data.company_referral };
      } catch (e) {
        return { success: false };
      }
    },
  },
  mounted() {
    this.getCompanies();
  },
  watch: {
    query: {
      handler(query) {
        console.log(query);
        this.total = this.filteredReferrals.length;
      },
      deep: true,
    },
  },
};
</script>



<style lang="scss">
/* Header styles */
.table th {
  border-radius: 3px;
  color: #1361ad;              /* White text */
  padding: 12px;            /* Padding for header cells */
  text-align: left;         /* Left-aligned text */
  font-size: 13px;
}


/* Row colors */
.table tr:nth-child(odd) {
  background-color: #f2faff; /* Very light blueish gray for odd rows */
}

.table tr:nth-child(even) {
  background-color: white; /* White for even rows */
}

.table td {
  font-size: 13px;
  padding: 10px;            /* Padding for data cells */
}

.table tr:hover {
  background-color: #ddd;    /* Hover effect */
}

.pagination button {
  background-color: #4CAF50; /* Button color */
  color: white;              /* Button text color */
  border: none;              /* Remove border */
  padding: 10px 15px;       /* Padding for buttons */
  margin: 0 5px;            /* Margin between buttons */
  cursor: pointer;           /* Pointer cursor on hover */
  border-radius: 5px;       /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition */
}

/* Pagination styles */
.row {
  display: none;
  flex-direction: row;
  justify-content: space-between; /* Center the pagination */
  align-items: center;
  padding: 5px;              /* Padding around pagination */
}

.row button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.pagination span {
  padding: 10px;            /* Padding for text */
  color: #333;              /* Text color */
}

</style>