import { Line, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  name: 'line-chart',
  extends: Line,
  mixins: [reactiveProp],
  props: {
    extraOptions: Object,
    gradientColors: {
      type: Array,
      default: () => [
        'rgba(72,72,176,0.2)',
        'rgba(72,72,176,0.0)',
        'rgba(119,52,169,0)',
      ],
      validator: (val) => val.length > 2,
    },
    gradientStops: {
      type: Array,
      default: () => [1, 0.4, 0],
      validator: (val) => val.length > 2,
    },
    width: {
      type: Number,
      default: window.innerWidth / 2.6 // Set a default width if desired
    },
    height: {
      type: Number,
      default: 300 // Set a default height if desired
    }
  },
  data() {
    return {
      ctx: null,
    };
  },
  methods: {
    updateGradients(chartData) {
      if (!chartData) return;
      const ctx = this.ctx || document.getElementById(this.chartId).getContext('2d');
      const genGradientStroke = this.gradientStops && this.gradientColors
          ? ctx.createLinearGradient(0, 230, 0, 50)
          : null;
      if (genGradientStroke) {
        genGradientStroke.addColorStop(this.gradientStops[0], this.gradientColors[0]);
        genGradientStroke.addColorStop(this.gradientStops[1], this.gradientColors[1]);
        genGradientStroke.addColorStop(this.gradientStops[2], this.gradientColors[2]);
      }
      chartData.datasets.forEach((set) => {
        const dsGradientStroke = set.gradientStops && set.gradientColors
            ? ctx.createLinearGradient(0, 230, 0, 50)
            : null;
        if (dsGradientStroke) {
          dsGradientStroke.addColorStop(set.gradientStops[0], set.gradientColors[0]);
          dsGradientStroke.addColorStop(set.gradientStops[1], set.gradientColors[1]);
          dsGradientStroke.addColorStop(set.gradientStops[2], set.gradientColors[2]);
        }
        set.backgroundColor = dsGradientStroke || genGradientStroke;
      });
    },
  },
  mounted() {
    this.$watch(
        'chartData',
        (newVal, oldVal) => {
          this.updateGradients(this.chartData);
          if (!oldVal || !oldVal.datasets || !oldVal.datasets.length) {
            this.renderChart(this.chartData, {
              ...this.extraOptions,
              maintainAspectRatio: false,
              responsive: true,
            });
          }
        },
        { immediate: true }
    );
    // Set chart dimensions if provided
    this.$el.style.width = this.width + 'px';
    this.$el.style.height = this.height + 'px';
  },
};
